<template>
  <div>
    <RefreshIcon class="mx-auto h-12 w-12 text-blue-400"/>
    <div class="text-center text-blue-400 font-bold text-sm tracking-wide uppercase">Loading</div>
  </div>
</template>

<script>
import RefreshIcon from '@/components/svg/RefreshIcon'

export default {
  components: {
    RefreshIcon,
  }
}
</script>
