<template>
  <div>
    <MzHeader />
    <MzForm v-model="id" @submit="fetchStockCount" />
    <MzLoading v-if="status === StatusEnum.loading" />
    <MzError v-if="status === StatusEnum.failed" />
    <MzData v-if="status === StatusEnum.done"
      :available="stockCount.available"
      :reserved="stockCount.reserved" />
  </div>
</template>

<script>
import MzData from '@/components/MzData'
import MzError from '@/components/MzError'
import MzForm from '@/components/MzForm'
import MzHeader from '@/components/MzHeader'
import MzLoading from '@/components/MzLoading'

const StatusEnum = Object.freeze({
  init: 0,
  loading: 1,
  done: 2,
  failed: 3,
})

export default {
  name: 'App',

  data () {
    return {
      id: this.$route.query.id || '',
      StatusEnum,
      status: StatusEnum.init,
      stockCount: {},
      apiBaseUrl: 'https://europe-west3-wawi-view.cloudfunctions.net/stock/',
    }
  },

  computed: {
    apiUrl () {
      return this.apiBaseUrl + this.id
    },
  },

  components: {
    MzData,
    MzError,
    MzForm,
    MzHeader,
    MzLoading,
  },

  methods: {
    processData (stockCount) {
      this.stockCount.available = stockCount > 0 ? stockCount : 0
      this.stockCount.reserved = stockCount < 0 ? -stockCount : null
    },

    replaceQuery () {
      const queryId = this.$route.query.id || ''
      if (this.id !== queryId) {
        const query = this.id ? { id: this.id } : {}
        this.$router.replace({ query })
      }
    },

    fetchStockCount () {
      this.replaceQuery()

      if (!this.id) {
        return this.status = this.StatusEnum.init
      }

      this.status = this.StatusEnum.loading
      fetch(this.apiUrl)
        .then((res) => {
          if (!res.ok) {
            throw Error(`Request failed with status ${res.status}`)
          }
          return res.json()
        })
        .then((data) => {
          this.status = this.StatusEnum.done
          this.processData(data.stockCount)
        })
        .catch(() => {
          this.status = this.StatusEnum.failed
        })
    },
  },

  mounted () {
    if (this.id) {
      this.fetchStockCount()
    }
  },
}
</script>
