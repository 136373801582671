<template>
  <div class="flex">
    <!-- available -->
    <div class="flex-1 w-full" v-if="available !== null">
      <MzCard title="Available" :quantity="available">
        <CheckCircleIcon v-if="available" solid class="h-8 w-8 text-green-400" />
        <XCircleIcon v-else solid class="h-8 w-8 text-red-400"/>
      </MzCard>
    </div>
    <!-- reserved -->
    <div class="flex-1 ml-4" v-if="reserved !== null">
      <MzCard title="Reserved" :quantity="reserved">
        <ExclamationCircleIcon solid class="h-8 w-8 text-yellow-400"/>
      </MzCard>
    </div>
  </div>
</template>

<script>
import MzCard from '@/components/MzCard'

import CheckCircleIcon from '@/components/svg/CheckCircleIcon'
import ExclamationCircleIcon from '@/components/svg/ExclamationCircleIcon'
import XCircleIcon from '@/components/svg/XCircleIcon'

export default {
  props: {
    available: {
      type: Number,
      default: null,
    },
    reserved: {
      type: Number,
      default: null,
    },
  },

  components: {
    MzCard,
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
  }
}
</script>
