<template>
  <div class="p-5 mx-auto bg-white rounded-xl shadow-md">
    <div class="flex justify-end -mr-1.5 -mt-1.5">
      <slot></slot>
    </div>
    <div class="mx-0 text-6xl font-medium text-black">{{ quantity }}</div>
    <div class="text-gray-400 font-bold text-sm tracking-wide uppercase">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: ['title', 'quantity']
}
</script>
