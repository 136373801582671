<template>
  <div>
    <XCircleIcon class="mx-auto h-12 w-12 text-red-400"/>
    <div class="text-center text-red-400 font-bold text-sm tracking-wide uppercase">Error</div>
  </div>
</template>

<script>
import XCircleIcon from '@/components/svg/XCircleIcon'

export default {
  components: {
    XCircleIcon,
  }
}
</script>
