<template>
  <form @submit.prevent="processForm">
    <label class="block mb-6">
      <span class="text-gray-500 px-3 text-sm font-medium">Check stock by product id</span>
      <div class="flex mt-1">
        <input v-model="id" @change="updateId" type="text" class="inline-block flex-grow rounded-l-xl
          border-gray-300 shadow-sm
          focus:border-blue-300
          focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50">
        <button type="submit" ref="submit"
          class="rounded-r-xl shadow-sm
          bg-blue-100
          border-gray-300 border
          text-blue-500 font-medium text-sm
          hover:bg-blue-200
          active:bg-blue-300
          transition duration-150 ease-in-out
          focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 focus:border-blue-300
          px-4
          "><SearchIcon solid class="h-6 w-6 text-blue-500"/></button>
      </div>
    </label>
  </form>
</template>

<script>
import SearchIcon from '@/components/svg/SearchIcon'

export default {
  props: ['value'],

  data () {
    return {
      id: this.value,
    }
  },

  methods: {
    updateId () {
      this.$emit('input', this.id)
    },

    processForm () {
      this.$refs.submit.focus()
      this.$emit('submit')
    },
  },

  components: {
    SearchIcon,
  },
}
</script>
